import React, { useState, useEffect } from "react";
import {
  CustomLoadingIndicator,
  CustomModal,
  CustomTooltipComponent,
  Header,
} from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Checkbox,
  MenuItem,
  Pagination,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { MdEdit, MdAdd, MdDelete, MdChevronRight } from "react-icons/md";
import { themeColorsUsable } from "../../data/buildData";
import { formatCustomDate } from "../../utils/helper";

const Campaigns = () => {
  const { loggedIn, loadingIndicatorActive, setLoadingIndicatorActive } =
    useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [allCampaigns, setAllCampaigns] = useState([]);
  const [draftCampaigns, setDraftCampaigns] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const [
    openDeleteSelectedCampaignsModal,
    setOpenDeleteSelectedCampaignsModal,
  ] = useState(false);

  const elementsDeleteSelectedCampaignsModal = [
    {
      type: "title",
      props: {
        label: "Deleting all selected campaigns",
      },
    },
    {
      type: "description",
      props: {
        label:
          "Are you sure you wish to delete all the selected campaigns? This action cannot be undone!",
      },
    },
  ];

  const handleOpenDeleteSelectedCampaignsModal = () => {
    setOpenDeleteSelectedCampaignsModal(true);
  };

  const handleCloseDeleteSelectedCampaignsModal = () => {
    setOpenDeleteSelectedCampaignsModal(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const getAllCampaigns = async () => {
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.get(
        `/api/v1/production/ga/campaigns?page=${page - 1}&per_page=${limit}`
      );

      setLoadingIndicatorActive(false);
      if (response.data.success) {
        if (!response.data.data.campaign_report.length && page > 1) {
          setPage(1);
        } else {
          setAllCampaigns(response.data.data.campaign_report);
          setTotalCampaigns(response?.data?.num_records);
          setTotalPages(Math.ceil(response?.data?.num_records / limit));
        }
      } else {
        setSnackbarMessage(response?.data?.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setLoadingIndicatorActive(false);
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    }
  };

  const deleteDraftCampaignClick = async (campaignIdToRemove) => {
    setLoadingIndicatorActive(true);
    let draft_campaigns = JSON.parse(localStorage.draft_campaigns);
    const updated_draft_campaigns = draft_campaigns.filter(
      (campaign) => campaign.id !== campaignIdToRemove
    );
    if (updated_draft_campaigns.length) {
      localStorage.draft_campaigns = JSON.stringify(updated_draft_campaigns);
    } else {
      localStorage.removeItem("draft_campaigns");
    }
    setDraftCampaigns(updated_draft_campaigns);
    setTimeout(() => {
      setLoadingIndicatorActive(false);
    }, 1000);
  };

  const handleDeleteCampaign = async (campaign_id) => {
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.post(
        "/api/v1/production/ga/delete_campaign",
        { campaign_id }
      );

      setLoadingIndicatorActive(false);
      if (response.data.success) {
        getAllCampaigns();
      } else {
        setSnackbarMessage(response?.data?.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setLoadingIndicatorActive(false);
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    }
  };

  const handleSelectCampaign = (campaignId) => {
    setSelectedCampaigns((prevSelected) =>
      prevSelected.includes(campaignId)
        ? prevSelected.filter((id) => id !== campaignId)
        : [...prevSelected, campaignId]
    );
  };

  const handleSelectAll = () => {
    if (selectedCampaigns.length === allCampaigns.length) {
      setSelectedCampaigns([]);
    } else {
      setSelectedCampaigns(allCampaigns.map(({ campaign }) => campaign.id));
    }
  };

  const handleDeleteSelectedCampaigns = async () => {
    setLoadingIndicatorActive(true);
    try {
      const response = await axiosPrivate.post(
        "/api/v1/production/ga/bulk_delete_campaigns",
        { selectedCampaigns }
      );

      setLoadingIndicatorActive(false);
      if (response.data.success) {
        setSelectedCampaigns([]);
        await getAllCampaigns();
        handleCloseDeleteSelectedCampaignsModal();
        setSnackbarMessage(response.data.message);
        setShowSnackbar(true);
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage(response?.data?.message);
        setShowSnackbar(true);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setLoadingIndicatorActive(false);
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    }
  };

  useEffect(() => {
    if (loggedIn) {
      getAllCampaigns();
      let draft_campaigns = localStorage.draft_campaigns
        ? JSON.parse(localStorage.draft_campaigns)
        : [];
      setDraftCampaigns(draft_campaigns);
    } else {
      navigate("/login");
    }
  }, [loggedIn]);

  useEffect(() => {
    getAllCampaigns();
  }, [page, limit]);

  return (
    <Box className="m-2 p-2 md:p-10 h-full bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Production" title="Campaigns" />
      <Box
        className={`flex ${
          selectedCampaigns.length ? "justify-between" : "justify-end"
        } items-center my-5`}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 999,
          backgroundColor: "#fff",
          padding: "20px 0px",
        }}
      >
        {!selectedCampaigns.length ? (
          <CustomTooltipComponent
            icon={MdAdd}
            tooltipText="Create a campaign"
            onClick={() => navigate("/create-campaign")}
            currentColor={themeColorsUsable.green}
          />
        ) : (
          <>
            <Typography>{`${selectedCampaigns.length} campaign(s) selected`}</Typography>
            <CustomTooltipComponent
              icon={MdDelete}
              tooltipText="Delete selected campaigns"
              onClick={handleOpenDeleteSelectedCampaignsModal}
              currentColor={themeColorsUsable.red}
            />
          </>
        )}
      </Box>
      <Box>
        {allCampaigns.length ? (
          <table id="groupDomainsTable">
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={selectedCampaigns.length === allCampaigns.length}
                    onChange={handleSelectAll}
                    color="primary"
                  />
                </th>
                <th>Campaign Name</th>
                <th>Mailing List Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allCampaigns.map(({ campaign, mailing_list }) => {
                return (
                  <tr key={campaign.id}>
                    <td>
                      <Checkbox
                        checked={selectedCampaigns.includes(campaign.id)}
                        onChange={() => handleSelectCampaign(campaign.id)}
                        color="primary"
                      />
                    </td>
                    <td>{campaign.name}</td>
                    <td>{mailing_list.name}</td>
                    <td>
                      <div>{campaign.state || "-"}</div>
                      {/* {campaign.state === "scheduled" ? (
                        <div>
                          Starts At:{" "}
                          <b>
                            {formatCustomDate(campaign.begins_at)}
                          </b>
                        </div>
                      ) : null} */}
                    </td>
                    <td>
                      {!selectedCampaigns.length ? (
                        <div className="flex justify-center">
                          <CustomTooltipComponent
                            icon={MdDelete}
                            tooltipText="Delete"
                            onClick={() => handleDeleteCampaign(campaign.id)}
                            currentColor={themeColorsUsable.red}
                          />
                        </div>
                      ) : null}
                      {/* {campaign.state === "idle" ||
                      campaign.state === "scheduled" ? (
                        <div className="flex justify-center">
                          <CustomTooltipComponent
                            icon={MdEdit}
                            tooltipText="Edit"
                            onClick={() => {
                              navigate("/create-campaign", {
                                state: { editingCampaignId: campaign.id },
                              });
                            }}
                            currentColor={themeColorsUsable.red}
                          />
                        </div>
                      ) : null} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : !loadingIndicatorActive ? (
          <Box sx={{ textAlign: "center" }}>No Campaigns Found</Box>
        ) : null}
        {allCampaigns.length ? (
          <Box className="flex justify-between items-center my-4">
            <Select
              value={limit}
              onChange={(e) => {
                setPage(1);
                setLimit(e.target.value);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            {totalCampaigns > limit ? (
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, newPage) => setPage(newPage)}
              />
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Box sx={{ my: 5 }}>
        <Typography variant="h4">Saved For Later</Typography>
        <Box sx={{ my: 3 }}>
          {draftCampaigns.length ? (
            <table id="groupDomainsTable">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Mailing List Name</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {draftCampaigns.map((campaign, index) => {
                  return (
                    <tr key={campaign.id}>
                      <td>{campaign.name || "-"}</td>
                      <td>{campaign.mailing_list?.label || "-"}</td>
                      <td>
                        <div>draft</div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          <CustomTooltipComponent
                            icon={MdChevronRight}
                            tooltipText="Continue Setup"
                            onClick={() =>
                              navigate("/create-campaign", {
                                state: { savedCampaign: campaign },
                              })
                            }
                            currentColor={themeColorsUsable.red}
                          />
                          <CustomTooltipComponent
                            icon={MdDelete}
                            tooltipText="Delete"
                            onClick={() => {
                              deleteDraftCampaignClick(campaign.id);
                            }}
                            currentColor={themeColorsUsable.red}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : !loadingIndicatorActive ? (
            <Box sx={{ textAlign: "center" }}>No Drafts Found</Box>
          ) : null}
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CustomLoadingIndicator isActive={loadingIndicatorActive} />

      <CustomModal
        open={openDeleteSelectedCampaignsModal}
        handleClose={handleCloseDeleteSelectedCampaignsModal}
        elements={elementsDeleteSelectedCampaignsModal}
        confirmFunction={handleDeleteSelectedCampaigns}
      />
    </Box>
  );
};

export default Campaigns;
