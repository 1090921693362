export function formatCustomDate(date) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  // Get the formatted date with a comma and 'AM'/'PM'
  let formatted = new Date(date).toLocaleString("en-US", options);

  // Remove the comma and convert 'AM'/'PM' to lowercase
  formatted = formatted
    .replace(" AM", "am")
    .replace(" PM", "pm")
    .replace(" at", "");

  return formatted;
}
