import {
  Alert,
  Box,
  MenuItem,
  Pagination,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomLoadingIndicator, Header } from "../../components";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const TrackPageClicks = () => {
  const axiosPrivate = useAxiosPrivate();
  const [loadingIndicatorActive, setLoadingIndicatorActive] = useState(false);
  const [userClicks, setUserClicks] = useState([]);

  const [totalUserClicks, setTotalUserClicks] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const getUserClicks = async () => {
    setLoadingIndicatorActive(true);

    try {
      const response = await axiosPrivate.get(
        `/api/v1/production/reports/getUserClicks?page=${
          page - 1
        }&limit=${limit}`,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data?.success) {
        setUserClicks(response.data?.data);
        setTotalUserClicks(response?.data?.total_records);
        setTotalPages(Math.ceil(response?.data?.total_records / limit));
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  useEffect(() => {
    getUserClicks();
  }, [page, limit]);

  return (
    <Box className="m-2 p-2 md:p-10 h-full bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Production" title="Track Page Clicks" />

      {userClicks.length ? (
        <table id="groupDomainsTable">
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>User ID</th>
              <th>Campaign Name</th>
              <th>Link</th>
              <th>Clicked times</th>
            </tr>
          </thead>
          <tbody>
            {userClicks.map((item) => {
              return (
                <tr key={item._id}>
                  <td style={{ textAlign: "left" }}>{item.userid}</td>
                  <td title={item.campname}>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {item.campname}
                    </Typography>
                  </td>
                  <td title={item.link}>
                    <a href={item.link} target="_blank">
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                          width: "400px",
                        }}
                      >
                        {item.link}
                      </Typography>
                    </a>
                  </td>
                  <td>{item.count}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : !loadingIndicatorActive ? (
        <Box sx={{ textAlign: "center" }}>No Results Found</Box>
      ) : null}

      {userClicks.length ? (
        <Box className="flex justify-between items-center my-4">
          <Select
            value={limit}
            onChange={(e) => {
              setPage(1);
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          {totalUserClicks > limit ? (
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, newPage) => setPage(newPage)}
            />
          ) : null}
        </Box>
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CustomLoadingIndicator isActive={loadingIndicatorActive} />
    </Box>
  );
};

export default TrackPageClicks;
