import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Pagination,
  Select,
  Snackbar,
} from "@mui/material";
import { CustomLoadingIndicator, Header } from "../../components";
import { formatCustomDate } from "../../utils/helper";

const ListedDomains = () => {
  const axiosPrivate = useAxiosPrivate();
  const [loadingIndicatorActive, setLoadingIndicatorActive] = useState(false);
  const [blacklistMonitors, setBlacklistMonitors] = useState([]);

  const [totalBlacklistMonitors, setTotalBlacklistMonitors] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const [openListedRBLDialog, setOpenListedRBLDialog] = useState(false);

  const [selectedBlacklistMonitor, setSelectedBlacklistMonitor] =
    useState(null);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const handleCloseListedRBLDialog = async () => {
    setOpenListedRBLDialog(false);
    setSelectedBlacklistMonitor(null);
  };

  const handleListedRBLClick = async (monitor) => {
    setOpenListedRBLDialog(true);
    setSelectedBlacklistMonitor(monitor);
  };

  const getBlacklistMonitors = async () => {
    setLoadingIndicatorActive(true);

    try {
      const response = await axiosPrivate.get(
        `/api/v1/warmup/reports/getBlacklistMonitors?page=${
          page - 1
        }&limit=${limit}`,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data?.success) {
        setBlacklistMonitors(response.data?.data[0]);
        setTotalBlacklistMonitors(response?.data?.data[1].Meta.Total_Records);
        setTotalPages(
          Math.ceil(response?.data?.data[1].Meta.Total_Records / limit)
        );
      }
    } catch (error) {
      setSnackbarMessage(error?.response?.data?.message);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
    } finally {
      setLoadingIndicatorActive(false);
    }
  };

  useEffect(() => {
    getBlacklistMonitors();
  }, [page, limit]);

  return (
    <Box className="m-2 p-2 md:p-10 bg-white rounded-3xl dark:text-gray-200 dark:bg-secondary-dark-bg">
      <Header category="Warm Up" title="Listed Domains" />

      {blacklistMonitors.length ? (
        <table id="groupDomainsTable">
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>Monitor</th>
              <th>Type</th>
              <th>Report</th>
              <th>Added</th>
              <th>Check</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {blacklistMonitors.map((item) => {
              return (
                <tr key={item.ID}>
                  <td style={{ textAlign: "left" }}>{item.Target}</td>
                  <td>{item.Type}</td>
                  <td>
                    {item.Blacklisted_Count > 0 ? (
                      <span
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleListedRBLClick(item)}
                      >
                        {item.Blacklisted_Count} Listed
                      </span>
                    ) : (
                      <span>{item.Blacklisted_Count} Listed</span>
                    )}
                  </td>
                  <td>{formatCustomDate(item.Add_Date * 1000)}</td>
                  <td>{formatCustomDate(item.Last_Check * 1000)}</td>
                  <td>{item.Status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : !loadingIndicatorActive ? (
        <Box sx={{ textAlign: "center" }}>No Blacklist Monitors Found</Box>
      ) : null}

      {blacklistMonitors.length ? (
        <Box className="flex justify-between items-center my-4">
          <Select
            value={limit}
            onChange={(e) => {
              setPage(1);
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          {totalBlacklistMonitors > limit ? (
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, newPage) => setPage(newPage)}
            />
          ) : null}
        </Box>
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CustomLoadingIndicator isActive={loadingIndicatorActive} />

      {selectedBlacklistMonitor ? (
        <Dialog open={openListedRBLDialog} onClose={handleCloseListedRBLDialog}>
          <DialogTitle>
            IPv4 Blacklist Monitor Report - {selectedBlacklistMonitor.Target}
          </DialogTitle>
          <DialogContent>
            <table id="groupDomainsTable">
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>RBL</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {selectedBlacklistMonitor.Blacklisted_On.map((item) => {
                  return (
                    <tr key={item.RBL}>
                      <td style={{ textAlign: "left" }}>{item.RBL}</td>
                      <td>
                        <Chip label="Listed" color="error" />
                      </td>
                      <td>
                        <Button
                          color="error"
                          variant="outlined"
                          onClick={() => window.open(item.Delist)}
                        >
                          Delist
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseListedRBLDialog}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Box>
  );
};

export default ListedDomains;
