import React, { useEffect, useRef, useState } from "react";
import {
  GeneralHtmlSupport,
  ClassicEditor,
  Autoformat,
  Bold,
  Italic,
  Underline,
  BlockQuote,
  Base64UploadAdapter,
  CloudServices,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  PictureEditing,
  Link,
  List,
  MediaEmbed,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableColumnResize,
  TableToolbar,
  TextTransformation,
  SourceEditing,
  LinkImage,
  ImageInsert,
  Alignment,
  Font,
} from "ckeditor5";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box } from "@mui/material";
import "ckeditor5/ckeditor5.css";
import "ckeditor5/ckeditor5-content.css";
import "ckeditor5/ckeditor5-editor.css";

const LandingPageEditor = ({ editorData, setEditorData }) => {
  const editorToolbarRef = useRef(null);
  const [isEditorMounted, setEditorMounted] = useState(false);

  useEffect(() => {
    setEditorMounted(true);

    return () => {
      setEditorMounted(false);
    };
  }, []);

  return isEditorMounted ? (
    <Box sx={{ my: 2 }}>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={setEditorData}
        config={{
          plugins: [
            ClassicEditor,
            GeneralHtmlSupport,
            SourceEditing,
            Autoformat,
            Bold,
            Italic,
            Underline,
            BlockQuote,
            Base64UploadAdapter,
            CloudServices,
            Essentials,
            Heading,
            Image,
            ImageCaption,
            ImageResize,
            ImageStyle,
            ImageToolbar,
            ImageInsert,
            LinkImage,
            PictureEditing,
            Link,
            List,
            MediaEmbed,
            Mention,
            Paragraph,
            PasteFromOffice,
            Table,
            TableColumnResize,
            TableToolbar,
            TextTransformation,
            Alignment,
            Font,
          ],
          toolbar: {
            items: [
              "undo",
              "redo",
              "|",
              "sourceEditing",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "underline",
              "|",
              "fontSize",
              "fontFamily",
              "fontColor",
              "fontBackgroundColor",
              "|",
              "alignment:left",
              "alignment:right",
              "alignment:center",
              "alignment:justify",
              "|",
              "link",
              "insertImage",
              "insertTable",
              "blockQuote",
              "mediaEmbed",
              "|",
              "bulletedList",
              "numberedList",
            ],
            shouldNotGroupWhenFull: true,
          },
          htmlSupport: {
            allow: [
              {
                name: /.*/,
                attributes: true,
                classes: true,
                styles: true,
              },
            ],
          },
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
              },
              {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
              },
              {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
              },
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
              },
              {
                model: "heading4",
                view: "h4",
                title: "Heading 4",
                class: "ck-heading_heading4",
              },
              {
                model: "heading5",
                view: "h5",
                title: "Heading 5",
                class: "ck-heading_heading5",
              },
              {
                model: "heading6",
                view: "h6",
                title: "Heading 6",
                class: "ck-heading_heading6",
              },
            ],
          },
          image: {
            resizeOptions: [
              {
                name: "resizeImage:original",
                label: "Default image width",
                value: null,
              },
              {
                name: "resizeImage:25",
                label: "25% page width",
                value: "25",
              },
              {
                name: "resizeImage:50",
                label: "50% page width",
                value: "50",
              },
              {
                name: "resizeImage:75",
                label: "75% page width",
                value: "75",
              },
              {
                name: "resizeImage:100",
                label: "100% page width",
                value: "100",
              },
            ],
            toolbar: [
              "imageTextAlternative",
              "toggleImageCaption",
              "|",
              "imageStyle:inline",
              "imageStyle:wrapText",
              "imageStyle:breakText",
              "|",
              "resizeImage",
              "|",
              "linkImage",
            ],
          },
          fontFamily: {
            supportAllValues: true,
          },
          fontSize: {
            options: [9, 11, 13, 16, 18, 20, 21, 24],
          },
          link: {
            addTargetToExternalLinks: true,
            defaultProtocol: "https://",
          },
          table: {
            contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
          },
        }}
        onReady={(editor) => {
          if (editorToolbarRef.current) {
            editorToolbarRef.current.appendChild(
              editor.ui.view.toolbar.element
            );
          }
        }}
        onAfterDestroy={(editor) => {
          if (editorToolbarRef.current) {
            Array.from(editorToolbarRef.current.children).forEach((child) =>
              child.remove()
            );
          }
        }}
      />
    </Box>
  ) : null;
};

export default LandingPageEditor;
